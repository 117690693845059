import Blank from "../Emails/Blank";
import Test from "../Emails/Test";
import Coffee from "../Emails/Coffee";
import Vegan from "../Emails/Vegan";

let defaultPages = [
    Test,
    Coffee,
    Vegan,
];

export default defaultPages
