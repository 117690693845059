export default [
    {
        path: '/',
        component: (resolve) => import(`@/views/Main/Wrapper.vue`).then(resolve),
        children: [
            {
                path: '',
                name: 'Home',
                meta: {
                    guest: true,
                    requires_membership: false
                },
                components: {
                    router_wrapper_main: (resolve) => import(`@/views/Main/Pages/Login.vue`).then(resolve),
                }
            },
            {
                path: 'login',
                name: 'Login',
                meta: {
                    guest: true,
                    requires_membership: false
                },
                components: {
                    router_wrapper_main: (resolve) => import(`@/views/Main/Pages/Login.vue`).then(resolve),
                }
            },
            {
                path: 'forgot-password',
                name: 'ForgotPassword',
                meta: {
                    guest: true,
                    requires_membership: false
                },
                components: {
                    router_wrapper_main: (resolve) => import(`@/views/Main/Pages/ForgotPassword.vue`).then(resolve),
                }
            },
            {
                path: 'reset-password-confirm/:uid/:token',
                name: 'ResetPasswordConfirm',
                meta: {
                    guest: true,
                    requires_membership: false
                },
                components: {
                    router_wrapper_main: (resolve) => import(`@/views/Main/Pages/ResetPasswordConfirm.vue`).then(resolve),
                }
            },
            {
                path: 'report/:campaign_id/:subscriber_id',
                name: 'ReportEmailAbuse',
                meta: {
                    guest: true,
                    requires_membership: false
                },
                components: {
                    router_wrapper_main: (resolve) => import(`@/views/Main/Pages/ReportEmailAbuse.vue`).then(resolve),
                }
            },
            {
                path: 'unsubscribe/:campaign_id/:subscriber_id',
                name: 'UnsubscribeFromCampaign',
                meta: {
                    guest: true,
                    requires_membership: false
                },
                components: {
                    router_wrapper_main: (resolve) => import(`@/views/Main/Pages/Unsubscribe.vue`).then(resolve),
                }
            },
            {
                path: 'wp-auth',
                name: 'WPAUTH',
                meta: {
                    guest: true,
                    requires_membership: false
                },
                components: {
                    router_wrapper_main: (resolve) => import(`@/views/Main/Pages/WpAuth.vue`).then(resolve),
                }
            },
            {
                path: 'logout',
                name: 'logout',
                meta: {
                    guest: true,
                    requires_membership: false
                },
                components: {
                    router_wrapper_main: (resolve) => import(`@/views/Main/Pages/Logout.vue`).then(resolve),
                }
            },
            {
                path: 'register',
                name: 'Signup',
                meta: {
                    guest: true,
                    requires_membership: false
                },
                components: {
                    router_wrapper_main: (resolve) => import(`@/views/Main/Pages/Signup.vue`).then(resolve),
                }
            },
        ]
    },
    {
        path: '/preview/',
        component: (resolve) => import(`@/views/Preview/Wrapper.vue`).then(resolve),
        children: [
            {
                path: 'campaign/',
                name: 'CampaignPreview',
                meta: {
                    guest: true,
                    requires_membership: false
                },
                components: {
                    router_wrapper_main: (resolve) => import(`@/views/Preview/Campaign.vue`).then(resolve),
                }
            },
        ]
    },
    {
        path: '/campaign/',
        component: (resolve) => import(`@/views/Preview/Wrapper.vue`).then(resolve),
        children: [
            {
                path: 'cp/',
                name: 'CP',
                meta: {
                    guest: true,
                    requires_membership: false
                },
                components: {
                    router_wrapper_main: (resolve) => import(`@/views/Preview/WpCampaign.vue`).then(resolve),
                }
            },
            {
                path: 'sp/',
                name: 'SP',
                meta: {
                    guest: true,
                    requires_membership: false
                },
                components: {
                    router_wrapper_main: (resolve) => import(`@/views/Preview/ShopifyCampaign.vue`).then(resolve),
                }
            },
            {
                path: 'popup/:id/:public_key',
                name: 'Popup',
                meta: {
                    guest: true,
                    requires_membership: false
                },
                components: {
                    router_wrapper_main: (resolve) => import(`@/views/Main/Pages/Popup.vue`).then(resolve),
                }
            }
        ]
    },
    {
        path: '/en/',
        component: (resolve) => import(`@/views/Preview/Wrapper.vue`).then(resolve),
        children: [
            {
                path: ':profile_url/:page_url',
                name: 'LeadSlideCampaign',
                meta: {
                    guest: true,
                    requires_membership: false
                },
                components: {
                    router_wrapper_main: (resolve) => import(`@/views/Preview/LsCampaign.vue`).then(resolve),
                }
            },
        ]
    },
    {
        path: '/shopify',
        component: (resolve) => import(`@/views/Shopify/Wrapper.vue`).then(resolve),
        children: [
            {
                path: 'auth/:token',
                name: 'shopifyAuth',
                meta: {
                    requiresAuth: false,
                    authGroups: [],
                    requires_membership: false
                },
                components: {
                    router_wrapper_cpanel: (resolve) => import(`@/views/Shopify/Auth.vue`).then(resolve),
                }
            }
        ]
    },
    {
        path: '/c-panel',
        component: (resolve) => import(`@/views/CPanel/Wrapper.vue`).then(resolve),
        children: [
            {
                path: 'dashboard',
                name: 'Dashboard',
                meta: {
                    requiresAuth: true,
                    authGroups:[1,2,3,4],
                    requires_membership: true
                },
                components: {
                    // router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/Business/Campaigns.vue`).then(resolve),
                    router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/Standard/Dashboard.vue`).then(resolve),
                }
            },
            {
                path: 'edit-campaign/:id',
                name: 'PluginEditCampaign',
                meta: {
                    requiresAuth: false,
                    authGroups:[],
                    requires_membership: true
                },
                components: {
                    router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/Plugin/EditCampaign.vue`).then(resolve),
                }
            },
            {
                path: 'dashboard',
                name: 'DashboardAlt',
                meta: {
                    requiresAuth: true,
                    authGroups:[1,2,3,4],
                    requires_membership: true
                },
                components: {
                    router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/Standard/Dashboard.vue`).then(resolve),
                }
            },
            {
                path: 'analytics',
                name: 'analytics',
                meta: {
                    requiresAuth: true,
                    authGroups:[1,2,3,4],
                    requires_membership: true
                },
                components: {
                    router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/Standard/Analytics.vue`).then(resolve),
                }
            },
            {
                path: 'pages',
                name: 'Pages',
                meta: {
                    requiresAuth: true,
                    authGroups:[1,2,3,4],
                    requires_membership: true
                },
                components: {
                    router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/Standard/Pages.vue`).then(resolve),
                }
            },
            {
                path: 'popup-designer',
                name: 'PopupDesigner',
                meta: {
                    requiresAuth: true,
                    authGroups:[1,2,3,4],
                    requires_membership: true
                },
                components: {
                    router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/Standard/PopupDesigner.vue`).then(resolve),
                }
            },
            {
                path: 'popups',
                name: 'Popups',
                meta: {
                    requiresAuth: true,
                    authGroups:[1,2,3,4],
                    requires_membership: true
                },
                components: {
                    router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/Standard/Popups.vue`).then(resolve),
                }
            },
            {
                path: 'themes',
                name: 'Themes',
                meta: {
                    requiresAuth: true,
                    authGroups:[1,2,3,4],
                    requires_membership: true
                },
                components: {
                    router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/Standard/Themes.vue`).then(resolve),
                }
            },
            {
                path: 'profile',
                name: 'Profile',
                meta: {
                    requiresAuth: true,
                    authGroups:[],
                    requires_membership: false
                },
                components: {
                    router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/Standard/Profile.vue`).then(resolve),
                }
            },
            {
                path: 'settings',
                name: 'Settings',
                meta: {
                    requiresAuth: true,
                    authGroups:[1,2,3,4],
                    requires_membership: false
                },
                components: {
                    router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/Standard/Settings.vue`).then(resolve),
                }
            },
            {
                path: 'signups',
                name: 'Signups',
                meta: {
                    requiresAuth: true,
                    authGroups:[1,2,3,4],
                    requires_membership: true
                },
                components: {
                    router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/Standard/Signups.vue`).then(resolve),
                }
            },
            {
                path: 'billing',
                name: 'Billing',
                meta: {
                    requiresAuth: true,
                    authGroups:[],
                    requires_membership: false
                },
                components: {
                    router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/Standard/Billing.vue`).then(resolve),
                }
            },
            {
                path: 'checkout/:id',
                name: 'Checkout',
                meta: {
                    requiresAuth: true,
                    authGroups:[],
                    requires_membership: false
                },
                components: {
                    router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/Standard/Checkout.vue`).then(resolve),
                }
            },
            {
                path: 'cancelled-checkout',
                name: 'CancelledCheckout',
                meta: {
                    requiresAuth: true,
                    authGroups:[],
                    requires_membership: false
                },
                components: {
                    router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/Standard/Cancel.vue`).then(resolve),
                }
            },
            {
                path: 'successful-checkout',
                name: 'SuccessfulCheckout',
                meta: {
                    requiresAuth: true,
                    authGroups:[],
                    requires_membership: false
                },
                components: {
                    router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/Standard/Success.vue`).then(resolve),
                }
            },
            {
                path: 'pdfs',
                name: 'PDFs',
                meta: {
                    requiresAuth: true,
                    authGroups: [1,2,3,4],
                    requires_membership: true
                },
                components: {
                    router_wrapper_cpanel: (resolve) => import(`@/views/DesignPlatform/Pdfs/Main.vue`).then(resolve),
                }
            }
        ]
    },
    {
        path: '/b-panel/',
        component: (resolve) => import(`@/views/CPanel/Wrapper.vue`).then(resolve),
        children: [
            {
                path: 'campaigns',
                name: 'Campaigns',
                meta: {
                    requiresAuth: true,
                    authGroups: [1, 2, 3, 4],
                    requires_membership: true
                },
                components: {
                    router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/Business/Campaigns.vue`).then(resolve),
                }
            },
            {
                path: 'publish',
                name: 'Publish',
                meta: {
                    requiresAuth: true,
                    authGroups: [1, 2, 3, 4],
                    requires_membership: true
                },
                components: {
                    router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/Business/Publish.vue`).then(resolve),
                }
            },
            {
                path: 'campaign/funnel/edit',
                name: 'EditCampaignFunnel',
                meta: {
                    requiresAuth: true,
                    authGroups: [1, 2, 3, 4],
                    requires_membership: true
                },
                components: {
                    router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/Business/EditCampaignFunnel.vue`).then(resolve),
                }
            },
            {
                path: 'page-creator-templates',
                name: 'PageCreatorTemplates',
                meta: {
                    requiresAuth: true,
                    authGroups: [1, 2, 3, 4],
                    requires_membership: true
                },
                components: {
                    router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/Business/PageCreatorTemplates.vue`).then(resolve),
                }
            },
            {
                path: 'page-creator/:id',
                name: 'PageCreator',
                meta: {
                    requiresAuth: true,
                    authGroups: [1, 2, 3, 4],
                    requires_membership: true
                },
                components: {
                    router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/Business/BlockCreator.vue`).then(resolve),
                }
            },
        ]
    },
    {
        path: '/a-panel/',
        component: (resolve) => import(`@/views/CPanel/Wrapper.vue`).then(resolve),
        children: [
            {
                path: '',
                name: 'AnalyticsA',
                meta: {
                    requiresAuth: true,
                    authGroups: [1],
                    requires_membership: true
                },
                components: {
                    router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/Admin/Analytics.vue`).then(resolve),
                }
            },
            {
                path: '',
                name: 'EmailsA',
                meta: {
                    requiresAuth: true,
                    authGroups: [1],
                    requires_membership: true
                },
                components: {
                    router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/Admin/Emails.vue`).then(resolve),
                }
            },
            {
                path: '',
                name: 'UsersA',
                meta: {
                    requiresAuth: true,
                    authGroups: [1],
                    requires_membership: true
                },
                components: {
                    router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/Admin/Users.vue`).then(resolve),
                }
            },
        ]
    },
    {
        path: '/design-tool/',
        component: (resolve) => import(`@/views/DesignPlatform/Wrapper.vue`).then(resolve),
        children: [
            {
                path: 'page/:is_template/:id',
                name: 'EditPage',
                meta: {
                    requiresAuth: true,
                    authGroups:[1,2,3,4],
                    requires_membership: true
                },
                components: {
                    router_wrapper_cpanel: (resolve) => import(`@/views/DesignPlatform/Pages/Edit.vue`).then(resolve),
                }
            },
            {
                path: 'popup/:is_template/:id',
                name: 'EditPopup',
                meta: {
                    requiresAuth: true,
                    authGroups:[1,2,3,4],
                    requires_membership: true
                },
                components: {
                    router_wrapper_cpanel: (resolve) => import(`@/views/DesignPlatform/Popups/Edit.vue`).then(resolve),
                }
            },
            {
                path: 'email/:is_template/:id',
                name: 'EditEmail',
                meta: {
                    requiresAuth: true,
                    authGroups: [1, 2, 3, 4],
                    requires_membership: true
                },
                components: {
                    router_wrapper_cpanel: (resolve) => import(`@/views/DesignPlatform/Emails/Edit.vue`).then(resolve),
                }
            },
            {
                path: 'pdfs/edit',
                name: 'EditPDF',
                meta: {
                    requiresAuth: true,
                    authGroups: [1, 2, 3, 4],
                    requires_membership: true
                },
                components: {
                    router_wrapper_cpanel: (resolve) => import(`@/views/DesignPlatform/Pdfs/Editor/Wrapper.vue`).then(resolve),
                }
            }
        ]
    },
    {
        path: '/campaign-design/',
        component: (resolve) => import(`@/views/DesignPlatform/Wrapper.vue`).then(resolve),
        children: [
            {
                path: 'funnel/page/',
                name: 'EditCampaignPage',
                meta: {
                    requiresAuth: true,
                    authGroups: [1, 2, 3, 4],
                    requires_membership: true
                },
                components: {
                    router_wrapper_cpanel: (resolve) => import(`@/views/DesignPlatform/Campaigns/Wrappers/Page`).then(resolve),
                }
            },
            {
                path: 'funnel/edit-page',
                name: 'EditCampaignPageDesign',
                meta: {
                    requiresAuth: true,
                    authGroups: [1, 2, 3, 4],
                    requires_membership: true
                },
                components: {
                    router_wrapper_cpanel: (resolve) => import(`@/views/DesignPlatform/Campaigns/Wrappers/Page`).then(resolve),
                }
            },
            {
                path: 'funnel/popup',
                name: 'EditCampaignPopup',
                meta: {
                    requiresAuth: true,
                    authGroups: [1, 2, 3, 4],
                    requires_membership: true
                },
                components: {
                    router_wrapper_cpanel: (resolve) => import(`@/views/DesignPlatform/Campaigns/Wrappers/Popup`).then(resolve),
                }
            },
            {
                path: 'funnel/edit-popup',
                name: 'EditCampaignPopupDesign',
                meta: {
                    requiresAuth: true,
                    authGroups: [1, 2, 3, 4],
                    requires_membership: true
                },
                components: {
                    router_wrapper_cpanel: (resolve) => import(`@/views/DesignPlatform/Campaigns/Wrappers/Popup`).then(resolve),
                }
            },
            {
                path: 'funnel/edit-email',
                name: 'EditCampaignEmailDesign',
                meta: {
                    requiresAuth: true,
                    authGroups: [1, 2, 3, 4],
                    requires_membership: true
                },
                components: {
                    router_wrapper_cpanel: (resolve) => import(`@/views/DesignPlatform/Campaigns/Email`).then(resolve),
                }
            },
        ]
    },
    {
        path: '*',
        component: (resolve) => import(`@/components/Errors/PageNotFound.vue`).then(resolve)
    },
];
