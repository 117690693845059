import Vue from 'vue'
import App from './App.vue'
import './plugins'
import './custom'
import router from './router'
import store from './store'
import './registerServiceWorker'
import vuetify from './plugins/vuetify';
import regeneratorRuntime from "regenerator-runtime";
import './assets/scss/app.scss'
import { VueReCaptcha } from 'vue-recaptcha-v3';

const exclude_captcha_pages = ['/campaign/popup/', '/campaign/cp/']
if (!exclude_captcha_pages.some(page => window.location.pathname.includes(page)))
{
  Vue.use(VueReCaptcha, { siteKey: '6LeKI2koAAAAAGYnVh1DF_G9iZ_WjJpIOnwUKLaA' });
}

Vue.use(regeneratorRuntime);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
