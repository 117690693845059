<template>
  <div v-if="pagination">
    <v-pagination
        color="blue-grey darken-4"
        v-model="pagination.page"
        :length="pagination_length"
        total-visible="6"
    ></v-pagination>
  </div>
</template>

<script>
export default {
  props: {
    max_width: {
      type: [Number],
      required: false,
      default: 200
    },
    watch_search: {
      type: [Boolean],
      required: true,
      default: false
    },
    pagination: {
      type: [Object],
      required: true,
      default: {
        url: '',
        search: {name: ''},
        limit: 24,
        page: 0
      }
    },
    return_results: {
      type: [Function],
      required: true
    }
  },
  components: {

  },
  data () {
    return {
      search_timeout: null,
      pagination_length: 0
    }
  },
  watch: {
    'pagination.search': {
      handler: function(after, before) {
        if(this.watch_search === true) {
          console.log("searching...");
          this.pagination.page = 1;
          // watch for type change and debounce the search
          this.debounceSearch()
        }
      },
      deep: true
    },
    'pagination.page': function(nv, ov) {
      this.getResults();
    }
  },
  created() {
    this.getResults();
  },
  methods: {
    debounceSearch(val){
      if(this.search_timeout) this.cancelTimeout(this.search_timeout);
      let self = this;
      this.search_timeout = setTimeout(function() {
        self.getResults();
      }, 300);
    },
    cancelTimeout(timeout) {
      clearTimeout(timeout)
    },
    getSearchString: (pagination) => {
      let search_object = pagination.search;
      let i = 0;
      let search_string = '';
      for( let prop in search_object) {
        if(search_object[prop] === '')
          continue
        if(i === 0)
        {
          search_string += '?'
        } else {
          search_string += '&'
        }
        search_string += prop + '=' + search_object[prop];
        i++;
      }
      // Add limits start and end
      if(search_string === '')
      {
        search_string += '?';
      } else {
        search_string += '&';
      }
      search_string += 'limit='+pagination.limit;
      let offset = 0;
      if(pagination.page > 1)
      {
        offset = (pagination.page - 1) * pagination.limit;
      }

      search_string += '&offset='+offset;

      return search_string;
    },
    getResults() {
      let search_string = this.getSearchString(
          this.pagination
      );

      let url = this.pagination.url;
      this.$hget(url+= search_string).then(r => {
        if(r.data.count > 0)
        {
          this.pagination_length = Math.ceil(r.data.count / (this.pagination.limit));
        }
        this.return_results(r.data.results);
      });
    }
  }
}
</script>